/* src/index.css */
/* Tailwind base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    overflow-x: hidden;
    font-family: "Almarai", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.image-container {
    position: relative;
    width: auto;
  }
  .product-image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    transition: background 0.3s ease;
  }
  .image-container:hover .overlay {
    background: rgba(0, 0, 0, 0);
  }
  .active-link {
    border-bottom: 1px solid white;
    padding-bottom: 5px; /* Adjust to create the desired margin */
  }
  
  .hover-link:hover {
    border-bottom: 1px solid white;
  }
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    text-align: center;
  }
  
  